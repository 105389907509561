import _ from 'lodash'

var reEmoji = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\ude97]|[\ud800-\udbff][\ude99-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff]|[\ufe0e-\ufe0f])/g;

function ttsSay(text, options = {}) {
  text = text.replace(reEmoji, '');
  console.log( 'ttsSay', text );
  var {speed=2, numberformat='number', avatarnumber=1, volume=2}=options||{};
  var avatar = document.querySelector('.chatroom-avatar');
  if ( avatar && avatar.contentWindow && avatar.contentWindow.PlayAudioByUrl ) {
    avatar.contentWindow.PlayAudioByUrl(`/tts?speed=${speed}&numberformat=${numberformat}&avatarnumber=${avatarnumber}&volume=${volume}&text=`+encodeURIComponent(text));
  }
}

// var texts = [''];
// function checkNewMessage() {
//   var chatbox = document.querySelector('.chat-box');
//   if ( chatbox ) {
//     var chatbox_iframe = chatbox.firstChild
//     if ( chatbox_iframe ) {
//       var nodes = chatbox_iframe.contentDocument.querySelectorAll('.chat-area.robot');
//       for(var i=texts.length; i<nodes.length; i++)  {
//         var text = nodes[i].innerText.replaceAll('\n','');
//         texts.push(text);
//         ttsSay(text);
//       }
//     }
//   }
// }
//setInterval(checkNewMessage, 200);
var texts = []
window.addEventListener('message', e => {
  // console.log( 'onMessage', e);
  const key = e.message ? 'message' : 'data';
  const data = e[key];
  if ( data.type == 0 ) {
    console.log( 'data', data )
    var text = data.data.split("你係咪想問")[0].split('<b>')[0];
    console.log( 'text', text )
    // texts.push( text )
    // if ( texts.length > 1 ) {

    const avatarId = _.last(window.location.pathname.split('/'));
    console.log({avatarId});

    switch (avatarId) {
      case 'wong-kwong-hon':
        ttsSay(text, { avatarnumber: 3 });
        break;
      case 'lam-chun':
        ttsSay(text, { avatarnumber: 2 });
        break;
      default:
        ttsSay(text, { avatarnumber: 1 });
        break;
    }

    // }
  }
},false);